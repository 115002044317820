class p404Page extends Component {

    static name() {
        return "p404Component";
    }

    static componentName() {
        return "p404Component";
    }

    getTemplate() {
        return `<div class="profile-view">
	                <headerSectionComponent></headerSectionComponent>
	                <div :class="classContainer" style="min-height: 36.5rem;">
	                    <div class="row justify-content-center">
                            <div class="row section-main">
                                <div class="col align-self-center">
                                    <img class="" src="/oo/ui/images/404_oppen.png" class="img-fluid    " alt="oppen 404">
                                </div>
                                <div class="col align-self-center">
                                    <template v-if="$route.query.message">
                                        <h4 class="col-12 align-self-center">{{tr('Information')}}</h4>
                                        <h5 class="col-12 align-self-center">
                                            {{tr($route.query.message)}}
                                        </h5>
                                    </template>
                                    <template v-else>
                                    <div class="alert alert-dark" role="alert">
                                          <h4 class="alert-heading">{{tr("Page Not Found")}}</h4>
                                          <p>Sorry if you can see this page something are not good.</p>
                                          <hr>
                                          <p class="mb-0">If this page appearly more the one time.</p>
                                        </div>
                                        <h4 class="col-12 align-self-center">from e-commerce</h4>
                                        <router-link to="/home" class="btn waves-effect waves-light" href="#" >
                                            <i class="fa fa-backward"></i>Go Home
                                        </router-link>
                                    </template>
                                </div>
                            </div>
                        </div>
	                </div>
	               <footerSectionComponent></footerSectionComponent>
	         </div>`;
    }
}

p404Page.registerComponent();